"use client";

import Link from "next/link";
import Image from "next/image";
import Styles from "@/styles/Buttons.module.css";
import { useEffect } from "react";

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    reset();
  }, [error]);

  return (
    <main className="full-wrapper pt-0 min-h-[60vh] w-full">
      <div className="wrapper py-12 md:py-36 relative flex gap-9 text-center flex-col justify-center items-center">
        <h1 className="text-pn-black-base !text-8xl sm:!text-9xl lg:!text-10xl">
          404
        </h1>

        <p className="h1 max-w-[500px]">
          Oops! We can’t seem to find the page you’re looking for.
        </p>

        <Link href="/" className={`btn btn-secondary btn-md rounded-btn`}>
          Home
        </Link>
      </div>
    </main>
  );
}
